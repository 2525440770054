.largeHeading {
    @apply heading3XL;
    @screen md {
        @apply hidden;
    }
    @screen sm {
        @apply hidden;
    }
}
.regularHeading {
    @apply headingXL;
    @screen lg {
        @apply hidden;
    }
    @screen sm {
        @apply hidden;
    }
}
.smallHeading {
    @apply headingL;
    @screen lg {
        @apply hidden;
    }
    @screen md {
        @apply hidden;
    }
}
.body {
    @apply mt-4;
}

.footNote {
    @apply mt-4 text-xs;
}

.ctaButton {
    @apply mt-4 mb-4;
}

.logoContainer {
    @apply flex items-end flex-col;
}

.logo {
    @apply max-w-xxxxxs;
}
