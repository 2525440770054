%productTileBase {
    @apply rounded-xlg flex flex-row col-span-2 cursor-pointer bg-center mb-3.5;
    @screen md {
        @apply col-span-3;
    }
}

%productTileButtonBase {
    @apply contentXXS font-bold leading-4 px-2 py-1 rounded-default;
}

.largeImage {
    @apply flex w-47 h-47 rounded-2xl bg-no-repeat object-cover;
}

.smallImage {
    @apply w-21.5 h-21.5 rounded-lg bg-no-repeat object-cover;
    @screen sm {
        @apply min-w-21.5;
    }
    @screen md {
        @apply min-w-21.5;
    }
}

.storeCategoryPreview {
    @apply flex text-center mt-8;
}

.productTileContainer {
    @extend %productTileBase;
}

.productTileTopContainer {
    @apply bg-no-repeat bg-center bg-cover h-45 relative rounded-xlg flex items-end justify-end;
}

.productTileLogo {
    @apply w-8;
}

.productTileOffersContainer {
    @apply absolute bottom-0 flex flex-col mb-4 ml-4;
}

.productTileBottomContainer {
    @apply relative ml-2 mt-0;
}

.productTileBottomWrapper {
    @apply flex flex-col justify-between max-w-xxxs;
}

.productOriginalPrice,
.productPrice,
.productPriceDiscounted {
    @apply contentXS inline text-gray40;
}

.productPriceDiscounted {
    @apply text-green-discount;
}

.productOriginalPrice {
    @apply line-through text-gray40 ml-2;
}

.productTileOfferButton,
.productTileLocationButton {
    @extend %productTileButtonBase;
}

.productTileOfferButton {
    @apply bg-white;
}

.productTileLocationButton {
    @apply bg-black mt-2 text-white;
}

.productMerchantName {
    @apply headingXS text-left top-4 text-gray40;
    @screen sm {
        @apply hidden;
    }
}

.productTileName {
    @apply headingXS text-left top-4;
}
