.quoteHighlightContainer {
    @apply flex flex-col mt-16 rounded-3xl;

    &Large {
        @apply p-16;
    }

    &Small {
        @apply p-6;
    }
}

.text {
    @apply contentL;
}

.authorContainer {
    @apply mt-4;
}

.author,
.authorInfo {
    @apply contentS;
}

.authorInfo {
    @apply font-bold;
}

.backgroundLemonDark {
    @apply bg-lemon-dark text-black;
}

.backgroundLemonPastel {
    @apply bg-lemon-pastel text-black;
}

.backgroundOrcaPastel {
    @apply bg-orca-pastel text-black;
}

.backgroundOrcaDark {
    @apply bg-orca-dark text-white;
}

.backgroundNone {
    @apply bg-none text-black;
}
