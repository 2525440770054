.contentDividerContainer {
    @apply flex flex-row justify-between flex-wrap rounded-xxlg mt-16 px-28 py-32;
    @screen md {
        @apply px-14 py-16;
    }
    @screen sm {
        @apply flex-col py-16 px-4;
    }
    &.backgroundLemonDark {
        @apply bg-lemon-dark;
    }
    &.backgroundLemonPastel {
        @apply bg-lemon-pastel;
    }
    &.backgroundOrcaDark {
        @apply bg-orca-dark;
        .contentGridHeader,
        .contentGridBody {
            @apply text-white;
        }
    }
    &.backgroundOrcaPastel {
        @apply bg-orca-pastel;
    }
    &.backgroundPlum {
        @apply bg-plum;
    }
}

.contentGridChild {
    @apply flex flex-col justify-start pr-8;
    flex: 1 1 50%;
    &:nth-child(even) {
        @apply pr-0;
    }
    &:nth-child(n + 3) {
        @apply mt-32;
        @screen sm {
            @apply mt-0;
        }
    }
    @screen sm {
        @apply mb-8 pr-0;
        &:last-child {
            @apply mb-0;
        }
    }
}

.contentGridHeader {
    @apply heading2XL mb-4;
    @screen sm {
        @apply headingXL mb-2;
    }
}

.contentGridBody {
    @apply contentM;
    @screen sm {
        @apply contentS;
    }
}
