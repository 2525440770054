.container {
    @apply mt-16;
    @screen sm {
        @apply mt-4;
    }
}

.background {
    @apply w-full bg-cyan-blue rounded-xxxlg flex flex-row justify-between p-28 relative;
    @screen md {
        @apply p-16;
    }

    @screen sm {
        @apply flex-col justify-center p-4 w-full;
    }
}

.content {
    flex: 0 0 60%;
    @screen md {
        flex: 0 0 50%;
    }
}

.headline {
    @apply mb-2;

    @screen lg {
        @apply mb-4;
    }

    p {
        @apply heading5XL font-bold;

        @screen md {
            @apply heading3XL;
        }

        @screen sm {
            @apply heading2XL;
        }
    }
}

.subHeadline {
    @apply headingXL mb-4;

    @screen sm {
        @apply hidden;
    }
}

.body {
    @apply contentXL;

    @screen md {
        @apply contentL;
    }

    @screen sm {
        @apply contentL;
    }
}

.phone {
    @apply absolute flex justify-end mr-0 text-right pr-28 right-0 -top-8;
    width: 40%;
    height: 115%;
    @screen md {
        @apply pr-16 w-2/4;
    }
    @screen sm {
        @apply justify-center -mt-12 pr-0 relative text-center top-16 w-full;
    }
    img {
        @apply inline-block;
        @screen sm {
            @apply w-3/5 h-full;
        }
    }
}

.ctaContainer {
    @apply flex flex-row justify-start mt-6;
    @screen sm {
        @apply flex-col justify-center;
    }
}

.ctaChildContainer {
    &:first-of-type {
        @apply mr-4;
        @screen sm {
            @apply mr-0;
        }
    }
    a[role="button"] {
        @apply w-full;
    }
    @screen sm {
        @apply mb-4;
    }
}
