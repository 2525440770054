.imageInterludeContainer {
    @apply flex flex-col my-16;
    .imageInterludeContainerTop {
        @apply flex flex-row mb-4;
        .wideImageContainer {
            &:last-of-type {
                @screen sm {
                    @apply flex mt-4;
                }
            }
        }

        @screen sm {
            @apply flex-col;
        }
    }

    .imageInterludeContainerBottom {
        @apply flex;
    }
}

.imageInterludeContainer {
    &Left {
        .imageInterludeContainerTop {
            @apply justify-end;
            .wideImageContainer {
                @apply flex;
                &:first-of-type {
                    img {
                        @apply rounded-xxlg;
                    }

                    @screen sm {
                        @apply justify-end ml-0 mr-8 pl-24;
                    }
                }

                &:last-of-type {
                    @apply ml-8;
                    img {
                        @apply rounded-bl-xxlg rounded-tl-xxlg;
                        @screen sm {
                            @apply rounded-xxlg;
                        }
                    }

                    @screen sm {
                        @apply justify-start ml-4 pr-24;
                    }
                }
            }
        }

        .imageInterludeContainerBottom {
            @apply pr-32;
            img {
                @apply rounded-br-xxlg rounded-tr-xxlg;
                @screen sm {
                    @apply rounded-xxlg;
                }
            }

            @screen sm {
                @apply pr-0;
            }
        }
    }
}

.imageInterludeContainer {
    &Right {
        .imageInterludeContainerTop {
            .wideImageContainer {
                &:first-of-type {
                    @apply mr-8;
                    img {
                        @apply rounded-br-xxlg rounded-tr-xxlg;
                        @screen sm {
                            @apply rounded-xxlg;
                        }
                    }

                    @screen sm {
                        @apply ml-8 mr-0 pr-24;
                    }
                }

                &:last-of-type {
                    img {
                        @apply rounded-xxlg;
                    }

                    @screen sm {
                        @apply justify-end mr-4 pl-24;
                    }
                }
            }
        }

        .imageInterludeContainerBottom {
            @apply justify-end pl-32;
            img {
                @apply rounded-bl-xxlg rounded-tl-xxlg;
                @screen sm {
                    @apply rounded-xxlg;
                }
            }

            @screen sm {
                @apply pl-0;
            }
        }
    }
}
