.dividerContainer {
    @apply flex flex-1 justify-between items-center pl-10 pr-6 py-6 border rounded-xxlg mt-16;

    @screen sm {
        @apply flex-col items-stretch px-6;
    }
}

.ctaDividerContent {
    @apply contentXL;
}

.childrenContainer {
    @apply ml-2 text-center;
    @screen sm {
        @apply mt-6 ml-0;
    }
    a[role="button"] {
        @screen sm {
            @apply w-full;
        }
    }
}

.backgroundLemonDark {
    @apply bg-lemon-dark text-black;
}

.backgroundLemonPastel {
    @apply bg-lemon-pastel text-black border-none;
}

.backgroundOrcaPastel {
    @apply bg-orca-pastel text-black border-none;
}

.backgroundOrcaDark {
    @apply bg-orca-dark text-white;
}

.backgroundNone {
    @apply bg-none text-black;
}
