@import "styles/arcade";

.container {
    @apply w-full relative mt-6;
    @screen md {
        @apply w-full mt-12;
    }
    @screen sm {
        @apply w-auto mt-4;
    }
}

.backgroundContainer {
    @apply relative aspectRatio12x5;

    @screen sm {
        @apply aspectRatio16x9;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center rounded-xxxlg bg-no-repeat;
}

.headlineContent {
    @apply font-afterpayyouth #{!important};
    @apply absolute top-0 left-8 max-w-sm font-black uppercase text-white;
    font-size: 12.5rem;
    line-height: 10.2rem;

    @screen sm {
        @apply text-5xl leading-none font-black left-4 top-1 max-w-heroMobile;
    }
}

.overlay {
    @apply bg-white w-128 rounded-xxxlg absolute flex flex-col;
    padding: 1.875rem;
    bottom: 1.875rem;
    gap: 1.875rem;
    &.overlayLeft {
        left: 1.875rem;
        @screen sm {
            @apply left-auto;
        }
    }
    &.overlayRight {
        right: 1.875rem;
        @screen sm {
            @apply right-auto;
        }
    }
    @screen sm {
        @apply relative w-auto rounded-xxxlg left-auto -mb-16 p-6 gap-3;
        transform: translateY(-10%);
    }
}

.overlayHeading {
    &, & > * {
        @extend %arcadeH3;
    }
    @screen sm {
        &, & > * {
            @extend %arcadeH4;
        }
    }
}

.overlayBody {
    @extend %arcadeP3;

    @screen sm {
        @extend %arcadeFooter2;
    }
}

.ctaContainer {
    @apply flex;

    @screen sm {
        @apply flex-col justify-between;
    }
}

.ctaOne {
    @apply pr-4;

    @screen sm {
        @apply pb-2 pr-0;
    }
}
