.countdown {
    @apply relative flex flex-row justify-between min-h-47 mt-16;
    @screen md {
        @apply flex-col items-center w-full mt-12;
    }
    @screen sm {
        @apply flex-col items-center w-full mt-4;
    }
}

.textContainer {
    @apply relative p-6 flex flex-col justify-center rounded-xxlg bg-mint w-3/4;
    @screen md {
        @apply flex-1 w-full;
    }
    @screen sm {
        @apply flex-1 w-full;
    }
}

.heading {
    @apply heading3XL;
}

.bodyContainer {
    @apply flex flex-col;
    @screen md {
        @apply flex-row items-center pb-6;
    }
    @screen sm {
        @apply flex-row items-center pb-6;
    }
}

.buttonContainer {
    @apply mt-4;
    @screen md {
        @apply ml-6 mt-0;
    }
    @screen sm {
        @apply ml-6 mt-0;
    }
}

.countDownContainer {
    @apply rounded-xxlg bg-black p-6 flex w-1/2 flex-row h-36 my-auto inset-y-0 flex-1 right-0 absolute justify-around;
    @screen md {
        @apply inset-x-auto w-10/12 bottom-auto mt-4;
        top: calc(100% - 50px);
    }
    @screen sm {
        @apply inset-x-auto w-10/12 h-auto bottom-auto mt-4;
        top: calc(100% - 50px);
    }
}

.unitContainer {
    @apply flex flex-col;
}

.unitCount {
    @apply heading6XL text-white font-bold;
    @screen md {
        @apply heading5XL;
    }
    @screen sm {
        @apply heading4XL;
    }
}

.unitName {
    @apply headingL text-white;
    @screen md {
        @apply headingL;
    }
    @screen sm {
        @apply headingM;
    }
}
