.overlay {
    @apply transition-opacity duration-200 opacity-100 h-full w-full bg-black-60 fixed backdrop-blur-2 top-0 left-0 z-999;
}

.inactiveOverlay {
    @apply transition-opacity duration-200 opacity-0 h-full w-full bg-black-60 fixed backdrop-blur-2 top-0 left-0 z-999;
}

.inactiveModalContainer {
    @apply transition-all duration-200 opacity-0 max-w-full max-h-full w-100 bg-white rounded-xxxlg fixed p-8 left-1/2 -ml-48 mt-120 flex flex-col translate-y-full h-0;
    @screen md {
        @apply -bottom-full;
    }

    @screen sm {
        @apply -bottom-full;
    }
}

.modalContainer {
    @apply transition-all duration-200 opacity-100 max-w-full max-h-full w-100 bg-white rounded-xxxlg mt-0 fixed p-8 left-1/2 -ml-48 top-4 flex flex-col translate-y-0 z-9999;
    height: calc(100% - 2rem);
    @screen md {
        @apply right-0 left-0 w-full bottom-0 h-auto top-auto rounded-br-none rounded-bl-none p-4 overflow-y-auto ml-0;
    }

    @screen sm {
        @apply right-0 left-0 w-full bottom-0 h-auto top-auto rounded-br-none rounded-bl-none p-4 overflow-y-auto ml-0;
    }
}

.modalHeader {
    @apply h-8 relative;
}

.modalBody {
    @apply flex-1;
}

.modalFooter {
    @apply relative;
}

.iconContainer {
    @apply cursor-pointer absolute top-4 right-4;
}
