.primary,
.secondary,
.tertiary,
.ghost,
.black,
.white,
.navigation,
.icon,
.carousel,
.disabled,
%secondary {
    @apply min-w-8 flex justify-center items-center h-12 text-sm text-black font-italianplate font-bold tracking-wide border-2 border-transparent leading-tight rounded-xlg w-max;
}

.chip {
    @apply w-max flex flex-row justify-center items-center bg-mint border-1 border-transparent text-black font-italianplate font-bold not-italic rounded-full whitespace-nowrap mx-1 cursor-pointer text-sm h-12 px-6 py-3 leading-5-5;
    &:active {
        @apply bg-mint;
    }
    &:focus,
    &:focus-visible {
        @apply outline-none border-black;
    }
    &:hover {
        @apply bg-mint-hover;
    }
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
.ghost:hover,
.black:hover,
.white:hover,
.navigation:hover,
.icon:hover .carousel:hover,
%secondary {
    @apply no-underline;
}

.overrideFocusBorder {
    @apply outline-none border-transparent border-2;

    &:focus {
        @apply border-black;
    }
}

.disabled:focus {
    @apply border-gray80;
    outline: none;
}

.primary {
    @apply bg-mint border-mint;
}

.primary:hover {
    @apply bg-mint-hover;
}

.primary:active {
    @apply bg-mint-press;
}

.secondary,
%secondary {
    @apply bg-plum border-plum;
}

.secondary:hover {
    @apply bg-plum-hover;
}

.secondary:active {
    @apply bg-plum-press;
}

.tertiary {
    @apply bg-orca border-orca text-white;
}

.tertiary:hover {
    @apply bg-orca-hover;
}

.tertiary:active {
    @apply bg-orca-press;
}

.ghost {
    @apply bg-white border-white text-orca;
}

.ghost:hover {
    @apply bg-ghost-hover;
}

.ghost:active {
    @apply bg-ghost-press;
}

.black,
%black {
    @apply bg-black text-white;
}

.white {
    @apply bg-white;
}

.searchSuggestion {
    @apply block p-2 rounded-xlg text-md bg-mint;
}

%cardBase {
    @apply font-bold leading-4 rounded-xlg;
}

.cardWhite,
.cardBlack {
    @extend %cardBase;
}

.cardWhite {
    @apply bg-white;
}

.cardBlack {
    @apply bg-black text-white;
}

.disabled,
.disabled:hover {
    @apply bg-gray80 border-gray80 text-gray30 cursor-default;
}

.large {
    @apply h-16 px-8 py-5 text-md;
}

.regular {
    @apply h-12 px-6 py-3 text-sm;
    @screen sm {
        @apply px-3 py-2;
    }
}

.small,
%small {
    @apply h-8 px-4 py-2 text-xs w-max;
}

.tiny {
    @apply h-6 px-2 py-1 contentXXS w-max;
    @apply leading-3-5;
    &:focus {
        outline: auto;
        @apply border-none;
    }
}

.navigation,
%navigation {
    @extend %black;
    @apply px-6 py-3 contentXS;
    @screen sm {
        @apply h-8 px-4 py-2 text-xs;
    }
}

.icon {
    @extend %navigation;
    @screen sm {
        @apply p-2;
    }
}

.fluid {
    @apply w-full;
}

.hide {
    @apply hidden;
}

.carousel,
%carousel {
    @apply bg-gray100 text-black;

    @extend %small;

    @screen sm {
        @apply px-1-25;
    }
}

.span {
    @apply font-bold;
}

.labelElementSpacer {
    @apply mr-1.5;
}
.offerLabel {
    @apply ml-0;
}

.fontWeight {
    &Light {
        @apply font-light;
    }

    &Bold {
        @apply font-bold;
    }
}

.arcade {
    @apply rounded-xxxxlg font-normal;
    &.chip, &.primary {
        &.primary {
            @apply border-black;
        }
        &.cardWhite {
            @apply bg-white text-black;
            &:active, &:hover {
                @apply bg-grey;
            }
        }
        @apply bg-black text-white;
        &:active, &:hover {
            @apply bg-black;
        }
    }
}