.container {
    @apply relative mt-8;
    padding-left: 25%;

    @screen sm {
        @apply w-auto pl-0;
    }
}

.backgroundContainer {
    @apply relative aspectRatio12x5;

    @screen sm {
        @apply aspectRatio16x9;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center rounded-xxlg bg-no-repeat;
}

.overlap {
    @apply w-124 bg-white rounded-xlg absolute p-8;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);

    @screen sm {
        @apply relative w-auto rounded-xxxlg left-auto mx-4 p-6 top-0 bottom-0 -mb-16;
        transform: translateY(-15%);
    }
}

.overlapHeading {
    @apply heading2XL;

    @screen lg {
        @apply heading3XL;
    }
}

.overlapBody {
    @apply contentL pt-4;

    @screen sm {
        @apply contentM;
    }
}

.ctaContainer {
    @apply flex pt-8;

    @screen sm {
        @apply flex-col justify-between pt-4;
    }
}

.ctaOne {
    @apply pr-4;

    @screen sm {
        @apply pb-2 pr-0;
    }
}
