.doubleHeadline {
    @apply relative aspectRatio16x9;
    @screen md {
        @apply ml-0 mr-0;
    }
    @screen sm {
        @apply aspectRatio1x1 ml-0 mr-0 p-0 mb-16;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center rounded-xxlg bg-no-repeat;
    @screen md {
        @apply ml-0 mr-0 rounded-none;
    }
    @screen sm {
        @apply ml-0 mr-0;
    }
}

.logo {
    @apply mb-4 max-w-xxxxs;
    max-height: 2rem;
}

.overlay {
    @apply bg-white rounded-xxxlg absolute p-8 pb-0 max-w-smd leading-none max-w-full rounded-tl-none rounded-br-none rounded-bl-none pl-0 left-0;
    bottom: 0;

    @screen md {
        @apply p-7 max-w-xlg pl-8 left-auto pb-0;
    }

    @screen sm {
        @apply relative p-6 w-auto mx-4 ml-auto mr-auto rounded-xxlg pl-6 pb-6 w-9/10 left-auto top-auto;
        transform: translateY(-40%);
    }
}

.overlayHeading {
    @apply mb-4;

    @screen lg {
        @apply heading5XL;
    }

    @screen md {
        @apply heading2XL;
    }

    @screen sm {
        @apply headingL;
    }
}

.overlayBody {
    @apply mb-4 font-bold text-gray80;

    @screen lg {
        @apply heading5XL;
    }

    @screen md {
        @apply heading2XL;
    }

    @screen sm {
        @apply headingL;
    }
}
