.inputWrapper {
    @apply align-middle inline-block font-italianplate max-w-full w-72 mt-4;
}

.fullWidth {
    @apply w-full;
}

.labelWrapper {
    @apply bg-white block border-0-1 box-border duration-200 relative rounded-xlg border-gray80 transition-all;

    &:hover:not(.disabled),
    &:focus-within {
        @apply border-black;
    }

    &Error {
        @apply border-fire;
    }
}

.errorText {
    @apply contentXXS text-fire mt-1;
}

.label {
    @apply absolute duration-200 overflow-hidden px-4 py-0 text-md text-gray40 transition-all whitespace-nowrap;
    top: 1.45rem;

    &.filled {
        @apply text-xs;
        top: 0.625rem;

        &.Small {
            top: 0.25rem;
        }
    }

    &.Small {
        @apply leading-normal;
        top: 0.865rem;
    }

    &.disabled {
        @apply text-gray40;
    }

    &Error {
        @apply text-fire;
    }
}

.inputText {
    @apply inline-block border-t-0 border-transparent box-border h-16 leading-normal outline-none px-4 py-0 text-black text-md w-full;
    background: none;

    @screen sm {
        @apply no-zoom-text-input;
    }

    &.filled {
        @apply border-t-10;
    }

    &.Small {
        @apply h-12 text-sm;
    }

    &::placeholder {
        @apply text-gray40;
    }
}

.helper {
    @apply flex mt-1 text-gray40 text-xs;
    &.charCount {
        @apply justify-end;
    }
}
