.header {
    @apply heading2XL mt-8;
}

.body {
    @apply mt-4;
    ol,
    ul {
        @apply pl-4;
    }
}
