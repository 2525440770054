.divider {
    @apply border-t-1 border-black;
    &.Hairline {
        @apply border-t-0-1 border-gray40;
    }

    &.Dashed {
        @apply border-dashed;
    }

    &.Solid {
        @apply border-solid;
    }

    &.Dotted {
        @apply border-dotted;
    }

    &.Small {
        @apply mt-6;
    }

    &.Large {
        @apply mt-16;
    }
}
