.imageInterludeContainer {
    @apply items-center flex my-16;
    .squareImageContainer {
        @apply flex-1;
        &Large {
            @screen sm {
                @apply flex-full;
            }
        }

        &Small {
            @apply flex flex-1 justify-center;
            @screen sm {
                @apply flex-full mx-4 mt-16;
            }
        }
    }

    @screen sm {
        @apply flex-wrap;
    }
}

.imageInterludeContainer {
    &Left {
        .squareImageContainer {
            &Large {
                img {
                    @apply rounded-br-xxlg rounded-tr-xxlg;
                }

                @screen sm {
                    @apply pr-32;
                }
            }

            &Small {
                @screen sm {
                    @apply justify-end pl-48;
                }
            }
        }
    }
}

.imageInterludeContainer {
    &Right {
        @apply flex-row-reverse;
        .squareImageContainer {
            &Large {
                @apply flex justify-end;
                img {
                    @apply rounded-bl-xxlg rounded-tl-xxlg;
                }

                @screen sm {
                    @apply pl-32;
                }
            }

            &Small {
                @screen sm {
                    @apply justify-start pr-48;
                }
            }
        }
    }
}
