.container {
    @apply w-full relative mt-6;
    @screen md {
        @apply w-full mt-12;
    }
    @screen sm {
        @apply w-auto mt-4;
    }
}

.backgroundContainer {
    @apply relative aspectRatio12x5;

    @screen sm {
        @apply aspectRatio16x9;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center rounded-xxlg bg-no-repeat;

    @screen sm {
        @apply mx-2;
    }
}

.overlay {
    @apply bg-white w-128 rounded-xlg absolute p-8;
    bottom: 0.5rem;
    &.overlayLeft {
        @apply left-2;
        @screen sm {
            @apply left-auto;
        }
    }
    &.overlayRight {
        @apply right-2;
        @screen sm {
            @apply right-auto;
        }
    }
    @screen sm {
        @apply relative w-auto rounded-xxxlg left-auto mx-4 -mb-16 p-6;
        transform: translateY(-10%);
    }
}

.overlayHeading {
    @apply heading5XL;

    @screen md {
        @apply heading3XL;
    }

    @screen sm {
        @apply heading2XL;
    }
}

.overlayBody {
    @apply contentL pt-4;

    @screen md {
        @apply pt-1;
    }

    @screen sm {
        @apply contentM;
    }
}

.ctaContainer {
    @apply flex pt-8;

    @screen md {
        @apply pt-1;
    }

    @screen sm {
        @apply flex-col justify-between pt-4;
    }
}

.ctaOne {
    @apply pr-4;

    @screen sm {
        @apply pb-2 pr-0;
    }
}
