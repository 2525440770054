.imageBlock {
    @apply inline-flex flex-row p-4 rounded-2xl bg-gray100 gap-4;
}

.largeImage {
    @apply flex w-47 h-47 rounded-2xl bg-no-repeat object-cover;
}

.rightImages {
    @apply grid grid-cols-2 gap-4;
}

.smallImage {
    @apply w-21.5 h-21.5 rounded-lg bg-no-repeat object-cover;
}

.moreCount {
    @apply h-21.5 rounded-lg flex items-center justify-center bg-white headingM text-gray60 object-contain;
}

.heading {
    @apply headingM;
}

.priceRange {
    @apply contentM;
}
