.trustpilotReviewContainer {
    @apply border-gray80 rounded-xlg;
    -webkit-clip-path: polygon(96% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 50px);
    clip-path: polygon(96% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 50px);
}

.trustpilotRatingContainer {
    @apply border-r-0-1 border-t-0-1 border-l-0-1 border-gray80 block p-4 rounded-xlg rounded-bl-none rounded-br-none;
    width: 144px;
    margin-bottom: -1px;
    background-color: white;
    position: relative;
}

.curvedTabEdgeTop {
    border-color: transparent transparent #cccccc #cccccc;
    border-style: solid;
    border-width: 1.5rem 1.5rem 1.5rem 1.5rem;
    border-top-left-radius: 10px;
    position: absolute;
    bottom: 0;
    right: -2.7rem;

    &:before {
        content: " ";
        border-color: transparent transparent #fff #fff;
        border-style: solid;
        border-width: 1.5rem 1.5rem 1.5rem 1.5rem;
        border-top-left-radius: 10px;
        position: absolute;
        bottom: -1.5rem;
        right: -1.45rem;
    }
}

.trustpilotReviewContent {
    @apply border-0-1 border-gray80 flex flex-col p-4 rounded-bl-xlg rounded-br-xlg rounded-tr-xlg;
    height: 236px;

    .reviewTitle {
        @apply font-bold text-lg;
    }

    .reviewBody {
        @apply my-2;
    }

    .reviewInfo {
        @apply contentS mt-auto;
    }

    .reviewAuthor {
        @apply font-bold;
    }
}

.trustpilotHeaderContainer {
    @apply flex flex-row items-center justify-between mb-10 mt-16;
    @screen sm {
        @apply mb-4 mx-4 mt-8;
    }
    svg {
        @screen sm {
            @apply hidden;
        }
    }
}

.trustpilotContent {
    @apply contentXL;
    span {
        @apply font-bold;
    }
    .reviewsCount {
        @apply font-normal;
        @screen sm {
            @apply hidden;
        }
    }
}

.trustpilotListContainer {
    @screen sm {
        @apply mx-4;
    }
    .slider {
        @screen sm {
            @apply px-4;
        }
    }
}
