%storeTileBase {
    @apply flex flex-col col-span-2 cursor-pointer rounded-xlg bg-cover bg-no-repeat w-full h-57 p-1 justify-between bg-center;
    @screen md {
        @apply h-48;
    }
    @screen sm {
        @apply col-span-2 h-48;
    }
}

%featuredStoreTileBase {
    @apply flex flex-col col-span-6 cursor-pointer rounded-xlg p-1 bg-cover bg-no-repeat w-full h-80 justify-between border-0-1 border-gray80 bg-center;
    @screen md {
        @apply col-span-4 h-48 rounded-xlg;
    }
    @screen sm {
        @apply col-span-6 h-48 rounded-xlg;
    }
}

%storeTileButtonBase {
    @apply contentXXS font-bold w-max leading-4 px-2 py-1 rounded-default;
}

.storeCategoryPreview {
    @apply flex text-center mt-8;
}

.storeTileContainer {
    @extend %storeTileBase;
}

.featuredStoreTileContainer {
    @extend %featuredStoreTileBase;
}

.storeTileTopContainer {
    @apply relative w-full bg-no-repeat bg-cover rounded-xlg;
}

.storeTileTopImageContainer {
    @extend %storeTileBase;
    @apply flex-1;
}
.storeTileOffersContainer {
    @apply m-1 flex flex-col;
    & > button:not(:first-child),
    & > a:not(:first-child) {
        @apply mt-2;
    }
}

.storeTileBottomContainer {
    @apply relative justify-start rounded-lg bg-white min-h-10  flex items-center gap-2 mt-1;
}

.storeTileImage {
    @apply rounded-t-xlg;
    @screen sm {
        @apply w-full;
    }
}

.storeTileLogoContainer {
    @apply flex justify-center items-center h-16 self-end w-16;
}

.storeTileLogo {
    @apply w-10 text-xs max-h-10 h-10 rounded-full object-contain;
    @apply border-[0.1px] border-[#eee];
}

.storeTileOfferButton,
.storeTileLocationButton {
    @extend %storeTileButtonBase;
}

.storeTileOfferButton {
    @apply bg-white w-max;
}

.storeTileLocationButton {
    @apply bg-black mt-2 text-white;
}

.storeTileName {
    @apply font-bold headingXS pr-4 text-left overflow-ellipsis overflow-hidden;
    @apply leading-4;
}
