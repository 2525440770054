.partnerLogosHeaderContainer {
    @apply border-b-0-1 border-black mb-6 mt-16;
    @screen sm {
        @apply mt-8;
    }
}

.partnerLogosHeader {
    @apply contentL mb-3;
}

.partnerLogoContainer {
    @apply flex flex-col justify-center border-0-1 border-grey-300 rounded-xl col-span-2 px-10 py-6;
    @screen sm {
        @apply px-7 py-5;
    }
    .partnerLogo {
        @apply w-full h-auto;
    }
}
