.imageTextContainer {
    @apply font-italianplate flex flex-row justify-between items-center mt-16;
    @screen sm {
        @apply flex-col h-auto px-4 py-6;
    }
}

.imageTextContainer {
    &.Left {
        .textContainer {
            @apply ml-16;
            @screen sm {
                @apply ml-0;
            }
        }
    }
}

.imageTextContainer {
    &.Right {
        .textContainer {
            @apply mr-16;
            @screen sm {
                @apply mr-0;
            }
        }
    }
}

.imageTextContainer {
    &.Right {
        @apply flex-row-reverse;
        @screen sm {
            @apply flex-col h-auto px-4;
        }
    }
}

.imageTextContainer {
    &.Right {
        .clusterContainer {
            @apply flex-row-reverse;
            .clusterContainerSmallTop {
                .clusterImage {
                    @apply rounded-tl-none rounded-tr-xlg;
                }
            }

            .clusterContainerSmallBottom {
                .clusterImage {
                    @apply rounded-bl-none rounded-br-xlg;
                }
            }

            .clusterContainerLarge {
                .clusterImage {
                    @apply rounded-l-xlg rounded-r-none;
                }
            }
        }
    }
}

.clusterContainer {
    @apply flex w-full;
    .clusterContainerSmall {
        @apply flex flex-col;
        flex: 1 1 33%;
    }

    .clusterContainerSmallTop {
        @apply flex flex-1 relative;
        .clusterImage {
            @apply rounded-tl-xlg w-full h-full;
        }
    }

    .clusterContainerSmallBottom {
        @apply flex flex-1 relative;
        .clusterImage {
            @apply rounded-bl-xlg w-full h-full;
        }
    }

    .clusterContainerLarge {
        @apply flex relative;
        flex: 1 1 67%;
        .clusterImage {
            @apply rounded-r-xlg w-full h-full;
        }
    }
}

.clusterImagePlaceholder {
    @apply aspectRatio1x1 w-full;
}

.textContainer {
    @apply flex-half;
    @screen sm {
        @apply pt-8 w-auto;
    }
}

.textContainerHeader {
    @apply font-bold leading-16 text-6xl;
    @screen sm {
        @apply leading-10 text-2xl;
    }
}

.textContainerBodyText {
    @apply font-normal leading-8 mt-4 text-xl;
    @screen sm {
        @apply text-md leading-6;
    }
}

.buttonContainer {
    @apply flex flex-row w-1/2 mt-6;
}
