.messageBannerContainer {
    @apply w-full h-auto rounded-xl flex items-center flex-row border;
}

.messageContainer {
    @apply flex-grow mt-2 mb-1.5 ml-6 contentXS inline;
}

.closeIcon {
    @apply flex-none py-2;
}

.ctaText {
    @apply contentXS underline flex-none ml-2;
}

.closeIconContainer {
    @apply mr-6 ml-2;
}

.backgroundBlack {
    @apply bg-black text-white border-black;
}

.backgroundMint {
    @apply bg-mint border-mint;
}

.backgroundFire {
    @apply bg-fire border-fire text-white;
}

.messageBannersContainerShadow {
    @apply invisible relative pt-2 -mb-3;
    @screen sm {
        @apply hidden;
    }
}

.messageBannersContainer {
    @apply z-50 w-full max-w-app;
    @screen lg {
        @apply fixed top-1.5 pr-30;
    }
    @screen md {
        @apply fixed top-1.5 pr-24;
    }
    @screen sm {
        @apply bottom-2 fixed pr-4;
    }
}

.bottomBanner {
    @apply z-50 w-full max-w-app;
    @screen lg {
        @apply bottom-0 fixed pr-30;
    }
    @screen md {
        @apply bottom-0 fixed pr-24;
    }
}
