.textHighlightContainer {
    @apply flex justify-between mt-16;
    @screen sm {
        @apply flex-col;
    }
}

.container {
    @apply flex flex-1 justify-start rounded-3xl;
    &Large {
        @apply p-16;
        .textHighlightContent {
            @apply content3XL;
        }
    }
    &Small {
        @apply p-6;
        .textHighlightContent {
            @apply contentXL;
        }
    }
}

.container {
    &:nth-child(2) {
        @apply ml-6;
        @screen sm {
            @apply ml-0 mt-6;
        }
    }
}

.backgroundLemonDark {
    @apply bg-lemon-dark text-black;
}

.backgroundLemonPastel {
    @apply bg-lemon-pastel text-black;
}

.backgroundOrcaPastel {
    @apply bg-orca-pastel text-black;
}

.backgroundOrcaDark {
    @apply bg-orca-dark text-white;
}

.backgroundNone {
    @apply bg-none text-black;
}
