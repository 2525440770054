%statisticBase {
    @apply flex flex-col;

    @screen sm {
        @apply col-span-4;
    }

    @screen md {
        @apply col-span-4 my-12;
    }

    @screen lg {
        @apply my-16;
    }
}

%containerBase {
    @apply rounded-xxxlg;

    @screen sm {
        @apply px-4 py-10;
    }

    @screen md {
        @apply px-24 py-12;
    }
}

.twoByTwoContainer {
    @extend %containerBase;

    @screen lg {
        @apply px-32 py-16;
    }
}

.threeByOneContainer {
    @extend %containerBase;

    @screen lg {
        @apply px-24 py-16;
    }
}

.fourByOneContainer {
    @extend %containerBase;

    @screen lg {
        @apply px-15 py-16;
    }
}

.metric {
    @apply font-bold;
}

.description {
    @apply headingL font-normal;
}

.twoByTwo {
    @extend %statisticBase;

    @screen lg {
        @apply col-span-6;
    }
}

.fourByOne {
    @extend %statisticBase;

    @screen lg {
        @apply col-span-3;
    }
}

.threeByOne {
    @extend %statisticBase;

    @screen lg {
        @apply col-span-4;
    }
}

.accentLemonPastel {
    @apply bg-lemon-pastel;
}

.accentLemonDark {
    @apply bg-lemon-dark;
}

.accentOrcaPastel {
    @apply bg-orca-pastel;
}

.accentOrcaDark {
    @apply bg-orca-dark text-white;
}

.LargestHeading {
    @apply heading8XL;
}

.SecondLargestHeading {
    @apply heading6XL;
}

.MuchLargerHeading {
    @apply heading4XL;
}

.LargerHeading {
    @apply heading2XL;
}

.LargeHeading {
    @apply headingL;
}
