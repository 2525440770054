.container {
    @apply w-106 rounded-xlg border-1 h-48 my-8 overflow-hidden;
    @screen sm {
        @apply w-full h-36;
    }
}

.header {
    @apply flex pt-5 px-5;
    @screen sm {
        @apply pt-4 px-4;
    }
}

.logo {
    svg {
        @apply rounded-xlg;
    }
}

.infoContainer {
    @apply pl-4 flex justify-center flex-col justify-around;
}

.title {
    @apply headingL;
}

.description {
    @apply contentM;
    p {
        @apply leading-3;
    }
}

.collection {
    @apply flex mb-4 mt-6 overflow-hidden;
    @screen sm {
        @apply mt-2 justify-evenly;
    }
}

.product {
    @apply mx-1.5 w-28 h-21;
    @screen sm {
        @apply w-22 h-17;
    }
    img {
        @apply rounded-xl w-28 h-21 object-cover;
        @screen sm {
            @apply w-22 h-17;
        }
    }
    &:first-child {
        @apply -ml-8;
    }
    &:last-child {
        @apply -mr-8;
    }
}
