.edGridContainer {
    @apply flex flex-wrap px-28 py-32 rounded-xxlg;
    .edGridChildContainer {
        @apply flex flex-row justify-start pr-8;
        flex: 1 1 50%;

        .edGridImageContainer {
            @apply flex-auto mr-6;
        }

        .edGridTextContainer {
            @apply flex flex-col justify-between;
            flex: 1 1 75%;

            .edGridSubheader {
                @apply font-bold leading-normal mb-4 text-lg;
            }

            .edGridTextBody {
                @apply font-normal leading-tight;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            @apply mb-32;
        }

        &:nth-child(5),
        &:nth-child(6) {
            @apply mt-32;
        }

        @screen sm {
            @apply mb-24 pr-0;
            &:last-child {
                @apply mb-0;
            }
        }
    }

    @screen sm {
        @apply flex-col px-4 py-16;
    }
}

.edGridContainer {
    &Indented {
        @apply m-16;
        @screen sm {
            @apply mx-0;
        }
    }
}

.edGridContainer {
    &Full {
        @apply my-16;
    }
}

.edGridContainer {
    &.bgLemonDark {
        @apply bg-lemon-dark;
    }

    &.bgLemonPastel {
        @apply bg-lemon-pastel;
    }

    &.bgOrcaDark {
        @apply bg-orca-dark;
        .edGridSubheader,
        .edGridTextBody {
            @apply text-white;
        }
    }

    &.bgOrcaPastel {
        @apply bg-orca-pastel;
    }
}
