.ctaClusterContainer {
    @apply py-16 rounded-xxlg;
    @screen sm {
        @apply pb-0 pt-16 px-0;
        .responsiveGrid {
            @apply p-0;
        }

        div {
            &:first-of-type {
                @apply py-0;
            }
        }
    }
}

.ctaClusterContainer {
    &Indented {
        @apply m-16;
        @screen sm {
            @apply mx-0;
        }
    }
}

.ctaClusterContainer {
    &Full {
        @apply my-16;
    }
}

.ctaClusterContainer {
    &.bgNone {
        .ctaContainer {
            @apply bg-orca-dark;
            .content {
                @apply text-white;
            }
        }
    }

    &.bgLemonDark {
        @apply bg-lemon-dark;
        .ctaContainer {
            @apply bg-lemon-pastel;
        }
    }

    &.bgLemonPastel {
        @apply bg-lemon-pastel;
        .ctaContainer {
            @apply bg-lemon-dark;
        }
    }

    &.bgOrcaDark {
        @apply bg-orca-dark;
        .clusterSubheader,
        .clusterTextBody {
            @apply text-white;
        }

        .ctaContainer {
            @apply bg-orca-pastel;
        }
    }

    &.bgOrcaPastel {
        @apply bg-orca-pastel;
        .ctaContainer {
            @apply bg-orca-dark;
            .content {
                @apply text-white;
            }
        }
    }
}

.clusterContainer {
    @apply col-span-12 flex mb-16 mx-28;
    .clusterContainerChild {
        @apply pr-8;
        .clusterSubheader {
            @apply font-bold leading-tight mb-4 text-2xl;
        }

        .clusterTextBody {
            @apply leading-normal mb-4 text-md;
        }

        @screen sm {
            @apply mb-16 pr-0;
        }
    }

    @screen sm {
        @apply flex-col mb-0 mx-8;
    }
}

.ctaContainer {
    @apply flex justify-between items-center col-span-12 mx-28 pl-8 pr-6 py-6 rounded-xxlg;
    .content {
        @apply font-bold leading-tight pr-8 text-2xl;
        @screen sm {
            @apply text-xl mb-8 pr-0;
        }
    }

    @screen sm {
        @apply flex-col items-stretch mx-0 rounded-tl-none rounded-tr-none;
    }
}
