.imageTextContainer {
    @apply font-italianplate flex flex-row justify-evenly items-center mt-16;
    @screen sm {
        @apply flex-col h-auto px-4;
    }
    &.bgLemonPastel {
        @apply bg-lemon-pastel;
    }

    &.bgOrcaPastel {
        @apply bg-orca-pastel;
    }
}

.imageTextContainer {
    &.Right {
        @apply flex-row-reverse;
        @screen sm {
            @apply flex-col h-auto px-4;
        }
    }
}

.imageContainer {
    @apply flex-col flex flex-shrink-0 rounded-xlg py-16;
    @screen sm {
        @apply self-center mr-6;
    }
}

.imageContainerPortrait {
    @apply relative rounded-xlg;
}

.imageTextContainer {
    &.Left {
        .imageContainerPortrait {
            left: 4rem;
            @screen sm {
                left: 2rem;
            }
        }
    }
}

.imageTextContainer {
    &.Right {
        .imageContainer {
            @screen sm {
                @apply ml-6 mr-0;
            }
        }
        .imageContainerPortrait {
            right: 4rem;
            @screen sm {
                right: 2rem;
            }
        }
    }
}

.textContainer {
    @screen lg {
        @apply flex-half;
    }
    @screen sm {
        @apply pt-8 w-auto;
    }
}

.imageTextContainer {
    &.Left {
        .textContainer {
            @apply ml-34;
            @screen sm {
                @apply ml-0;
            }
        }
    }
}

.imageTextContainer {
    &.Right {
        .textContainer {
            @apply mr-34;
            @screen sm {
                @apply mr-0;
            }
        }
    }
}

.textContainerHeader {
    @apply heading6XL mb-4;

    @screen md {
        @apply heading3XL;
    }

    @screen sm {
        @apply leading-10 text-2xl;
    }
}

.textContainerBodyText {
    @apply contentM;

    @screen lg {
        @apply contentXL;
    }
}

.imageContainer {
    &.accentLemonDark {
        @apply bg-lemon-dark;
    }
}

.imageContainer {
    &.accentLemonPastel {
        @apply bg-lemon-pastel;
    }
}

.imageContainer {
    &.accentOrcaDark {
        @apply bg-orca-dark;
    }
}

.imageContainer {
    &.accentOrcaPastel {
        @apply bg-orca-pastel;
    }
}

.imageContainer {
    &.accentPlum {
        @apply bg-plum;
    }
}
