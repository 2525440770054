.headerContainer {
    @apply flex mb-2 items-center mt-7;
}

.headerTitle {
    @apply headingXL pb-0 flex-1;
}

.subTitle {
    @apply contentL ml-2;
}

.headerButton {
    @apply pb-2 relative -bottom-1;
    a {
        @apply px-2;
    }
}

.tileContainer {
    @apply px-16 -mx-16 outline-none relative;

    @screen md {
        @apply -mx-12 px-12 overflow-hidden;
    }

    @screen sm {
        @apply -mx-2 px-2 overflow-hidden;
    }

    ul {
        cursor: default !important;
    }
}

.leftArrow {
    @apply absolute vertical-center opacity-0 hidden;
}

.leftArrowCol {
    @apply absolute -top-14 opacity-0 hidden;
}

.arrowEnter {
    @apply opacity-0 block;
}

.leftArrowEnterActive {
    @apply left-8 opacity-100 block;
    transition: all 200ms;
}

.leftArrowEnterDone {
    @apply left-8 opacity-100 block;
}

.leftArrowExit {
    @apply left-8 opacity-100 block;
}

.leftArrowExitActive {
    @apply opacity-0 block;
    transition: all 200ms;
}

.leftArrowExitDone {
    @apply opacity-0 hidden;
}

.rightArrowCol {
    @apply absolute -top-14 opacity-0 hidden;
}

.rightArrow {
    @apply absolute vertical-center opacity-0 hidden;
}

.rightArrowEnterActive {
    @apply right-8 opacity-100 block;
    transition: all 200ms;
}

.rightArrowEnterDone {
    @apply right-8 opacity-100 block;
}

.rightArrowExit {
    @apply right-8 opacity-100 block;
}

.rightArrowExitActive {
    @apply opacity-0 block;
    transition: all 200ms;
}

.rightArrowExitDone {
    @apply opacity-0 hidden;
}
