.appHighlightContainer {
    @apply border-b-1 border-black flex mb-15 pt-15 px-15;
    @screen md {
        @apply px-6;
    }
    @screen sm {
        @apply flex-col-reverse mb-4 px-4 pt-4;
    }
}

.appHighlightImageContainer {
    @apply flex flex-1 items-end justify-center mx-2;
    .appImageChildContainer {
        @apply w-full;
        &:first-of-type {
            @apply z-1;
        }

        &:last-of-type {
            @apply relative right-16;
            @screen sm {
                @apply hidden;
            }
        }
    }

    @screen sm {
        @apply mt-8;
    }
}

.frontImage {
    @apply w-full;
}

.textContainer {
    @apply flex-1 mb-4;
    .textContainerHeader {
        @apply mb-4 heading6XL;
        @screen sm {
            @apply heading3XL;
        }
    }

    .textContainerBody {
        @apply contentXL;
        @screen sm {
            @apply contentL;
        }
    }

    .buttonContainer {
        @apply flex flex-row mt-6;
        .buttonChild {
            @apply flex-1 mt-6;
            &:first-of-type {
                @apply mr-3;
                @screen sm {
                    @apply mr-0;
                }
            }

            &:last-of-type {
                @apply ml-3;
                @screen sm {
                    @apply ml-0;
                }
            }
        }

        @screen sm {
            @apply mt-8 flex-col;
        }
    }

    @screen sm {
        @apply mb-0;
    }
}
