.cta4x3 {
    @apply aspectRatio4x3 relative rounded-b-xxlg mt-16;

    @screen md {
        @apply hidden mt-12;
    }

    @screen sm {
        @apply hidden mt-4;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-auto w-1/2 right-0 bg-cover bg-center rounded-br-xxlg rounded-tr-xxlg bg-no-repeat;
}

.logo {
    @apply mb-4 max-w-xxxxs max-h-8;
}

.overlay {
    @apply bg-white rounded-xxxlg absolute p-8 w-1/2;
    transform: translateY(-50%);
    top: 50%;
}

.filterHeading {
    @apply headingL font-bold mt-8;
}

.buttonsContainer {
    @apply flex flex-wrap mt-4;
}

.overlayHeadingSmall {
    @apply heading2XL;
}

.overlayHeadingRegular {
    @apply heading3XL;
}

.overlayHeadingLarge {
    @apply heading7XL;
}

.overlayBody {
    @apply headingL;
}
