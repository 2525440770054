@import "styles/variables";

.fullBleed {
    @apply relative rounded-b-xxlg col-span-12 -mx-16;
    margin-top: -#{$headerHeight};

    @screen md {
        @apply col-span-8 -mx-12;
    }

    @screen sm {
        @apply col-span-4 -mx-4;
        margin-top: -#{$mobileHeaderHeight};
    }
}

.backgroundContainer {
    @apply relative aspectRatio16x9;

    @screen sm {
        @apply aspectRatio1x1;
    }
}

.background {
    @apply absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center rounded-b-xxlg bg-no-repeat;
}

.logo {
    @apply mb-4 max-w-xxxxs;
    max-height: 2rem;
}

.overlay {
    @apply bg-white rounded-xxxlg absolute p-8 max-w-smd;
    transform: translateY(-50%);
    top: 50%;
    left: 60px;

    @screen md {
        @apply p-7 max-w-sm;
    }

    @screen sm {
        @apply relative p-6 w-full mx-auto left-auto right-auto top-auto;
        transform: translateY(-40%);
    }
}

.overlayHeading {
    @apply mb-4;

    @screen lg {
        @apply heading3XL;
    }

    @screen md {
        @apply heading2XL;
    }

    @screen sm {
        @apply headingXL;
    }
}

.overlayBody {
    @screen lg {
        @apply headingL;
    }

    @screen md {
        @apply headingM;
    }

    @screen sm {
        @apply headingS;
    }
}
