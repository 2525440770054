%flexColBase {
    @apply flex flex-col justify-center;
}

.slidesContainer {
    scroll-snap-type: both mandatory;

    @apply mt-4 overflow-y-auto flex flex-row relative;
    @screen lg {
        @apply mt-16 overflow-y-auto h-144 flex-col;
    }

    &::-webkit-scrollbar {
        @apply w-0;
    }

    .slideRow {
        @apply flex;

        @screen sm {
            @apply flex-col mr-20;
        }
        @screen md {
            @apply flex-col mr-20;
        }

        &:not(:last-child) {
            scroll-snap-align: start;
        }

        &:last-child {
            scroll-snap-align: end;
        }
    }

    .iphoneShellContainer {
        @apply sticky top-0 h-0;
        svg {
            @apply h-144;
        }
        @screen sm {
            @apply hidden;
        }
        @screen md {
            @apply hidden;
        }
    }

    .imageContainer {
        @apply w-40 flex-shrink-0;
        img {
            @apply w-full h-82;
        }

        @screen lg {
            @apply w-70;
            img {
                @apply w-full h-140;
            }
        }

        .mobileIphoneShellContainer {
            @apply absolute;

            svg {
                @apply h-84;
            }
            @screen lg {
                @apply hidden;
            }
        }
    }

    .contentContainer {
        @extend %flexColBase;
        .header {
            @apply heading2XL mt-8;
        }

        .subheader {
            @apply font-bold headingM mt-6;
        }

        .body {
            @apply contentS mt-4;
        }

        @screen lg {
            @apply px-30 h-141;
            .header {
                @apply heading8XL;
                @screen md {
                    @apply heading5XL;
                }
            }

            .subheader {
                @apply font-bold heading3XL mt-6;
                @screen md {
                    @apply headingXL;
                }
            }

            .body {
                @apply contentL mt-4;
                @screen md {
                    @apply contentM;
                }
            }
        }
    }
}
